import React,{useEffect} from "react"
import Layout from "../../../../components/layout"
import Seo from "../../../../components/seo"
import { connect } from "react-redux"
import {getPropertyAction} from '../../../../redux/propertiesDucks'
import { getType, getOperations, getFakeAddres, getLocation } from "../../../../helpers/helper.properties"
import { getCover } from "../../../../helpers/helper.rendering"

const ClientSideOnlyLazy = React.lazy(() =>
  import("../../../../components/Propiedad/main")
)
const ClientSideOnlyLazyAbout = React.lazy(() =>
  import('../../../../components/Propiedad/about')
)

//Components
import Expand from '../../../../components/Propiedad/expand'
import { useGetPropertyQuery } from "../../../../redux/middlewareTokkoApi/properties"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useGetPropertyQuery as useGetPropertyMediacoreQuery } from "../../../../redux/mediacore/properties"
import useQueryProperty from "../../../../hooks/useQueryProperty"

const IndexPage = (props) => {

  const isSSR = typeof window === "undefined"

  const {id} = props

  const { realEstate } = useStaticQuery(graphql` 
    query {
        realEstate {
            name
            keys{
              tokko
            }
            env{
              CLIENTID
            }
        }
  }`)


  const { allPropertyData, isLoading, isError, isFetching } = useQueryProperty(id);
  
  useEffect(() => {
    if(isError || (!isFetching && !isLoading && !allPropertyData)){
      navigate("/404")
    }
  },[allPropertyData,isLoading,isError,isFetching])


  return (
    <>
      <Layout property={allPropertyData} preloader={true}>
            {/* <Seo 
                title={getType(serverData) + " en "  + getOperations(serverData)[0] + " - " + getFakeAddres(serverData)}
                meta={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                description={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                image={getCover(serverData.photos)?.original}
            /> */}
            <Seo 
                title={"Venta y Alquiler de Propiedades"}
                meta={"Encontrá la propiedad que estabas buscando."} 
                description={"Encontrá la propiedad que estabas buscando."} 
            />
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy id={id} />
                <ClientSideOnlyLazyAbout id={id} />
              </React.Suspense>
            )}
      </Layout>
    </>
  )
}

export default connect(state => ({
    api_key: state.settings.keys.tokko
}),null)(IndexPage);

// export async function getServerData(context) {
  // return true
  // try {
  //   const res = await fetch("https://staging.mediacore.app/api/blog/novedades/2/get_data_detail/?client=2")
  //   console.log(res)
  //   if (!res.ok) {
  //     throw new Error(`Response failed`)
  //   }
  //   return {
  //     props: await res.json(),
  //   }
  // } catch (error) {
  //   return {
  //     status: 500,
  //     headers: {},
  //     props: {}
  //   }
  // }
// }
